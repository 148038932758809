<template>
  <div>
    <div class="header-main">
      <h1 class="py-4 page-title-main">
        {{ $t("routeTitles.calendar.calendar") }}
      </h1>
      <slot name="actions" />
    </div>
    <div class="px-main">
      <v-tabs v-model="tab" class="t-tab-custom">
        <v-tab v-for="(item, i) in items" :key="i" :to="item.to">{{
          item.title
        }}</v-tab>
      </v-tabs>
      <slot name="default" />
    </div>
  </div>
</template>

<script>
import { checkPermissionTab } from '@/utils/permissions'
export default {
  name: 'BaseCalendar',
  data () {
    return {
      tab: null,
      items: [],
      itemsTmp: [
        {
          title: this.$t('routeTitles.calendar.roomAssignment'),
          to: '/calendar/room-assignment',
          pathRoot: '/calendar/room-assignment',
          order: 1
        },
        {
          title: this.$t('routeTitles.calendar.carPark'),
          to: '/calendar/car-park',
          pathRoot: '/calendar/car-park',
          order: 2
        },
        {
          title: this.$t('routeTitles.calendar.exhibitsForHire'),
          to: '/calendar/exhibits-for-hire',
          pathRoot: '/calendar/exhibits-for-hire',
          order: 3
        }
      ]
    }
  },
  components: {},
  mounted () {
    this.checkPerTab()
  },
  methods: {
    checkPermissionTab,
    checkPerTab () {
      let itemCheck = this.checkPermissionTab(this.itemsTmp)
      itemCheck = [...new Set(itemCheck)]
      itemCheck.sort((a, b) => a.order - b.order)
      this.items = itemCheck
    }
  }
}
</script>

<style lang="scss">
  .header-main {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
</style>
