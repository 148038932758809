export function getRoomNumberFromName(name) {
  if (!name) return 0;
  const digits = name.replace(/\D/g, '');
  return Number(digits);
}

export function compareRoom(a, b) {
  // compare room by .name.
  // convert the name to number, ignoring non-numeric characters.
  const aRoomNumber = getRoomNumberFromName(a.name);
  const bRoomNumber = getRoomNumberFromName(b.name);

  // if two are equal, compare it by name lexicographically.
  if (aRoomNumber === bRoomNumber) {
    return a.name.localeCompare(b.name);
  }
  return aRoomNumber - bRoomNumber;
}
