export function areLinked (booking1, booking2) {
  return (
    // check the continuousity
    booking1.checkInDate === booking2.checkOutDate ||
    booking1.checkOutDate === booking2.checkInDate
  // no need to check representative name. see UAT pms-200
  // ) && booking1.representative && (
  //   // check the representative name
  //   booking1.representative.name === booking2.representative?.name
  ) && booking1.contract?.clientId && (
    // the same client
    booking1.contract?.clientId === booking2.contract?.clientId
  )
}
