<template>
  <div :style="`height: ${maxHeight}px`">
    <booking-in-room
      v-for="(booking, ind) in bookingsWithOverlap"
      :key="booking.id"
      :index="ind"
      :booking="booking"
      :overlaps="booking.overlaps"
      :maxHeight.sync="maxHeight"
      :dateColWidth="dateColWidth"
      :fromDate="fromDate"
      :linked="isLinked(booking)"
      @dragstart="$listeners.dragstart"
    />
  </div>
</template>

<script>
import BookingInRoom from './BookingInRoom.vue'
import { areLinked } from '@/utils/booking'

export default {
  components: { BookingInRoom },
  props: {
    fromDate: String,
    bookings: Array,
    allBookings: Array,
    dateColWidth: Number
  },
  data () {
    return {
      maxOverlaps: 0,
      maxHeight: 22
    }
  },
  computed: {
    bookingsWithOverlap () {
      const result = []
      this.bookings.forEach((booking, index) => {
        const pastOverlaps = new Set(result.filter((b) =>
          b.checkInDate < booking.checkOutDate &&
          b.checkOutDate > booking.checkInDate
        ).map(b => b.overlaps))
        let overlaps = 0
        // find the overlaps that has no confliction
        for (;overlaps < pastOverlaps.size; overlaps++) {
          if (!pastOverlaps.has(overlaps)) {
            break
          }
        }
        if (this.maxOverlaps < overlaps) {
          this.maxOverlaps = overlaps
        }

        result.push({
          ...booking,
          overlaps
        })
      })
      return result
    }
  },
  methods: {
    isLinked (booking) {
      return this.allBookings.some(b => areLinked(b, booking))
    }
  }
}
</script>
