<template>
  <v-menu open-on-hover bottom offset-y v-model="showData">
    <template v-slot:activator="{on, attrs}">
      <div
        class="booking"
        :class="classes"
        :style="style"
        :draggable="draggable"
        @dragstart="dragstart"
        @click="click"
        @mouseenter="enter"
        @mouseleave="leave"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon :class="colorText" v-if="!draggable" small>mdi-pin-outline</v-icon>
        <v-icon :class="colorText" v-if="linked" small>mdi-link-variant</v-icon>
        <span v-if="priorityBooking" small>【優先予約】</span>
          <span :class="colorText">{{ name }}({{ booking | bookingTypeShort }}){{guestCount}}</span>
          <span :class="colorText">{{booking.bookingBarNote}}</span>
      </div>
    </template>
    <v-card class="booking-detail-menu">
      <div>{{ booking.bookingBarNote }}</div>
      {{booking.room.name}}・{{booking.room.roomType.name}}・{{booking.room.roomType.facility.name}}・{{guestCountDetail}}<br>
      {{clientName}} ({{ booking | bookingTypeShort }}) 会員番号 {{memberId}}<br>
      宿泊代表者: {{name}}<br>
      予約作成日時: {{booking.createdAt | dateTimeSting({ delimeter: 'ja' }) }}<br>
      <template v-if="clientId">
        <v-divider />
        <template v-if="$apollo.loading">読み込み中...</template>
        <template v-else>
          過去の{{booking.room.roomType.facility.name}}の宿泊
          <div v-for="pastBooking in pastBookings" :key="pastBooking.id">
            {{pastBooking.checkInDate | jaLongDateSlash}} - {{pastBooking.checkOutDate | jaLongDateSlash}} {{pastBooking.room.name}}
          </div>
          <div v-if="pastBookings.length === 0">
            過去の予約はありません
          </div>
        </template>
      </template>
    </v-card>
  </v-menu>
</template>

<script>
import { getNumberOfDaysBetween, isoDate, addDays } from '@/utils/dateUtil'
import { getBackgroundColorOfBooking } from '@/constants/contractColors'
import gql from 'graphql-tag'

const bookingHeight = 20
const marginWidth = 2

const totalHeight = bookingHeight + marginWidth * 2
export default {
  components: { },
  props: {
    fromDate: String,
    overlaps: Number,
    index: Number,
    booking: Object,
    maxHeight: Number,
    dateColWidth: Number,
    linked: Boolean
  },
  data () {
    return {
      showData: false,
      dataLoad: false,
      pastBookings: []
    }
  },
  computed: {
    draggable () {
      return !this.booking.roomFixed && !this.booking.checkedInAt
    },
    name () {
      return this.booking.representative?.name ?? 'お名前未設定'
    },
    clientName () {
      return this.booking.contract?.client.name ?? this.booking.client?.name
    },
    type () {
      return this.booking.bookingType.code
    },
    priorityBooking () {
      return this.booking.priorityBooking
    },
    style () {
      const booking = this.booking
      const days = getNumberOfDaysBetween(booking.checkInDate, booking.checkOutDate)
      const left = getNumberOfDaysBetween(this.fromDate, booking.checkInDate)

      let leftStyle
      let width
      if (left >= 0) {
        // no overflow in the left
        leftStyle = `left: ${this.dateColWidth * (left + 0.5)}px`
        width = `width: ${this.dateColWidth * days}px`
      } else {
        // left overflow so
        leftStyle = `left: ${0}px`
        width = `width: ${this.dateColWidth * (days + left + 0.5)}px`
      }
      const height = `height: ${bookingHeight}px`

      const top = `top: ${this.overlaps * totalHeight}px`
      const margin = `margin: ${marginWidth}px 0`

      if (this.maxHeight < (this.overlaps + 1) * totalHeight) {
        this.$emit('update:maxHeight', (this.overlaps + 1) * totalHeight)
      }

      return [leftStyle, width, height, top, margin].join('; ')
    },
    classes () {
      const color = getBackgroundColorOfBooking(this.booking)
      return [color].join(' ')
    },
    colorText () {
      const color = getBackgroundColorOfBooking(this.booking)
      const arrTextWhite = ['bg-contract--vm', 'bg-contract--mo', 'bg-contract--ota', 'bg-contract--other-reservations', 'bg-contract--block']
      if (arrTextWhite.includes(color)) {
        return 'text-white'
      } else return 'text-black'
    },

    memberId () {
      return this.booking.contract?.client?.memberId
    },
    guestCountNums () {
      const {
        adults,
        children,
        bedShares
      } = this.booking.computedForList
      return { adults, children, bedShares }
    },
    guestCount () {
      const { adults, children, bedShares } = this.guestCountNums

      let result = `${adults}`
      if (children || bedShares) {
        result += `+${children}(${bedShares})`
      }
      return result
    },
    guestCountDetail () {
      const { adults, children, bedShares } = this.guestCountNums

      return `大${adults}小${children}添${bedShares}`
    },
    clientId () {
      return this.booking.contract?.clientId ?? this.booking.clientId
    }
  },
  methods: {
    click () {
      const booking = this.booking
      const bookingID = booking.id
      const bookingTypeId = parseInt(booking.bookingType.id)
      let routeData = this.$router.resolve({
        path: `/reservation/contract-detail?id=${bookingID}&tab=basic-information`
      })

      if (bookingTypeId === 11) {
        if (booking.blockRoom) {
          routeData = this.$router.resolve({
            path: `/booking/Block-Reservation?id=${bookingID}&tab=Block-Reservation`
          })
        }

        if (booking.priorityBooking) {
          routeData = this.$router.resolve({
            path: `/reservation/contract-detail?id=${bookingID}&tab=basic-information`
          })
        }
      }

      window.open(routeData.href, '_blank')
    },
    dragstart (event, dragId) {
      // event.dataTransfer.effectAllowed = 'move'
      // event.dataTransfer.dropEffect = 'move'
      event.dataTransfer.setData('booking-id', this.booking.id)
      this.showData = false
      this.$emit('dragstart', { booking: this.booking })
    },
    async enter () {
      this.dataLoad = true
      this.showData = true
    },
    leave () {
      this.showData = false
    }
  },
  apollo: {
    pastBookings: {
      query: gql`
query ($toDate: DateString!, $facilityId: Int!, $clientId: Int!) {
  bookingList(filter: {
    toDate : $toDate
    facilityId: $facilityId
    cancelled: false
    clientId: $clientId
  }, orderBy: {
    fieldName: "booking.checkInDate"
    direction: "DESC"
  }, pagination: {skip:0, take: 20}) {
    items{
      id
      checkInDate
      checkOutDate
      room {
        name
      }
    }
  }
}`,
      variables () {
        return {
          toDate: isoDate(addDays(new Date(), -1)),
          facilityId: this.booking.room.roomType.facility.id,
          clientId: this.clientId
        }
      },
      skip () {
        return !this.dataLoad || !this.clientId
      },
      update: (data) => data.bookingList.items
    }
  }
}
</script>

<style lang="scss" scoped>
.booking {
  margin: 2px 0;
  padding: 0px 2px;
  border-radius: 4px;

  & ::v-deep .v-icon {
    color: white;
  }
}
.text-white {
  color: white !important
}
.text-black {
  color: black !important
}

.booking-detail-menu {
  padding: 1em;
  max-height: 300px;
  overflow: auto;
}
</style>
