<template>
  <div>
    <template v-if="$apollo.loading && !refreshing">
      <div>読み込み中</div>
    </template>
    <template v-else>
      <!-- cancel wait -->
      <div class="d-flex">
        <div class="t-calendar-item__title">
          在庫
        </div>
        <remaining-inventory v-for="date in dateRange" :key="date"
          :rooms="rooms"
          :bookings="getBookingsByDate(date)"
          ref="invCol"
        />
      </div>

      <div class="d-flex room-row" v-for="room in listedRooms" :key="room.id"
          @drop="assignRoom($event, room.id)"
          @dragover.prevent
          @dragenter.prevent
      >
        <div class="t-calendar-item__title">
          {{room.name}}
        </div>
        <div class="d-flex room-assignment-room-container"
        >
          <div class="t-calendar-item__data-item room-and-booking-area" v-for="date in dateRange" :key="date">
          </div>
          <bookings-in-room
            :fromDate="fromDate"
            :dateColWidth="dateColWidth"
            :bookings="getBookingsByRoomId(room.id)"
            :allBookings="bookings"
            :key="`bookingsInRoom${room.id}`"
            @dragstart="dragstart"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import gql from 'graphql-tag'
import { getDateRangeArray } from '@/utils/dateUtil'
import RemainingInventory from './RemainingInventory.vue'
import BookingsInRoom from './BookingsInRoom.vue'
import { compareRoom } from '../../../../utils/room'

export default {
  components: {
    RemainingInventory,
    BookingsInRoom
  },
  props: {
    fromDate: String,
    toDate: String,
    completed: Function,
    roomType: Object,
    refreshCount: Number,
    dateColWidth: Number,
  },
  data () {
    return {
      rooms: [],
      bookings: [],
      refreshing: false,
      targetBooking: null
    }
  },
  computed: {
    dateRange () {
      return getDateRangeArray(this.fromDate, this.toDate)
    },
    roomTypeId () { return this.roomType.id },
    listedRooms() { return this.rooms.filter(r => r.isListed) },
    hasNoListedRooms () {
      // graphql query for rooms is finished and listedRooms is empty
      return !this.$apollo.loading && this.listedRooms.length === 0
    },
  },
  methods: {
    getBookingsByDate (date) {
      return this.bookings.filter(b => b.checkInDate <= date && b.checkOutDate > date)
    },
    getBookingsByRoomId (roomId) {
      return this.bookings.filter(b => b.room.id === roomId)
    },
    async refresh (v) {
      this.refreshing = true
      try {
        await this.$apollo.queries.bookings.refetch()
      } finally {
        this.refreshing = false
      }
    },
    dragstart ({ booking }) {
      this.targetBooking = booking
    },
    async assignRoom (_event, roomId) {
      if (this.targetBooking) {
        if (this.targetBooking.roomId === roomId) {
          return
        }
        await this.$apollo.mutate({
          mutation: gql`mutation ($bookingId: Int!, $roomId: Int!) {
  allocateRoom(
    bookingId: $bookingId
    newRoomId: $roomId
  ) {
    __typename
  }
}`,
          variables: {
            bookingId: this.targetBooking.id,
            roomId
          }
        })

        await this.$apollo.queries.bookings.refetch()
      }
    }
  },
  watch: {
    bookings (counts) {
      this.completed({ counts })
    },
    async refreshCount () {
      await this.refresh()
    }
  },
  apollo: {
    rooms: {
      query: gql`
        query (
          $roomTypeId: Int!
        ) {
          roomList(
            roomTypeId: $roomTypeId
          ) {
            id
            name
            isListed
          }
        }
      `,
      update: (data) => data.roomList.sort((a, b) => compareRoom(a, b)),
      variables () {
        return {
          roomTypeId: this.roomTypeId
        }
      }
    },
    bookings: {
      query: gql`
query (
  $fromDate: DateString!
  $toDate: DateString!
  $roomTypeId: Int!
) {
  bookingList: bookingListForRoomAssignment(filter: {
    fromDate: $fromDate
    toDate : $toDate
    roomTypeId: $roomTypeId
    cancelled: false
  }) {
    items{
      id #Int! 予約ID
      blockRoom # trueならブロック予約となる
      bookingType {id code} #カッコ内に出すタイプ名
      bookingGroupId
      roomFixed # Boolean! true なら部屋固定
      bookingBarNote
      createdAt
      representative {
        clientId # subMemberIdと併せて、連続した複数の予約で同じclientId subMemberIdである場合にリンク表示
        subMemberId #
        name #代表者の名前 nullの場合は空白で出す
      }
      priorityBooking # Boolean, trueなら優先予約となる
      checkInDate
      checkOutDate
      checkedInAt
      roomFixed
      roomId
      room {
        id name #部屋のIDと名前
        roomTypeId
        roomType {id name facility {id name}}
      }
      clientId
      client {name}
      contract {
        clientId
        client {
          memberId # 会員ID
          name
        }
      }
      computedForList {
        adults
        children
        bedShares
      }
    }
  }
}
      `,
      update: (data) => data.bookingList.items,
      variables () {
        return {
          roomTypeId: this.roomTypeId,
          fromDate: this.fromDate,
          toDate: this.toDate
        }
      },
      fetchPolicy: 'no-cache'
    }
  }
}
</script>

<style lang="scss" scoped>
.room-and-booking-area {
  background-color: #eeeeee;
  border-color: white;
}

.room-assignment-room-container {
  position: relative;
  & ::v-deep .booking {
    position: absolute;
    height: 17px !important;
    margin: 0 !important;
  }
}

.room-assignment-room-container{
  overflow: hidden;
}
</style>
