var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('base-calendar',{scopedSlots:_vm._u([{key:"actions",fn:function(){return [_c('SearchBooking')]},proxy:true},{key:"default",fn:function(){return [_c('facility-room-type-calendar',{ref:"calendar",attrs:{"double":"","hideMwCol":"","excludeRoomTypesWithNoListedRooms":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var fromDate = ref.fromDate;
      var toDate = ref.toDate;
      var roomType = ref.roomType;
      var completed = ref.completed;
      var refreshCount = ref.refreshCount;
      var dateColWidth = ref.dateColWidth;
return [_c('room-assignment-room-type',{attrs:{"refreshCount":refreshCount,"fromDate":fromDate,"toDate":toDate,"roomType":roomType,"completed":completed,"dateColWidth":dateColWidth}})]}}])})]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }