<template>
  <div class="t-calendar-item__data-item" :class="{overbooked}">
    {{ remaining }} <span v-if="priorityBookingCount"> (優先 {{priorityBookingCount}})</span>
  </div>
</template>

<script>
export default {
  props: {
    rooms: Array,
    bookings: Array
  },
  computed: {
    remaining () {
      const roomCount = this.rooms.length
      const bookingCount = this.bookings.length

      const remaining = roomCount - bookingCount
      return remaining
    },
    priorityBookingCount () {
      return this.bookings.filter(b => b.priorityBooking).length
    },
    overbooked () {
      if (this.remaining < 0) return true
      // check if any of the individual room is overbooked
      const roomIds = new Set()
      for (const booking of this.bookings) {
        const id = booking.roomId
        if (roomIds.has(id)) {
          return true
        }
        roomIds.add(id)
      }
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.overbooked {
  background-color: red;
  color: #fff !important
}
</style>
