<template>
  <base-calendar>
    <template v-slot:actions>
      <SearchBooking></SearchBooking>
    </template>
    <template v-slot:default>
      <facility-room-type-calendar
        ref="calendar"
        double
        hideMwCol
        excludeRoomTypesWithNoListedRooms
      >
          <!-- facility,
          mwCalendarCols, -->
        <template v-slot:default="{
          fromDate,
          toDate,
          roomType,
          completed,
          refreshCount,
          dateColWidth
        }">
          <room-assignment-room-type
            :refreshCount="refreshCount"
            :fromDate="fromDate"
            :toDate="toDate"
            :roomType="roomType"
            :completed="completed"
            :dateColWidth="dateColWidth"
          />
        </template>
      </facility-room-type-calendar>
    </template>
  </base-calendar>
</template>

<script>
import gql from 'graphql-tag'
import FacilityRoomTypeCalendar from '@/components/FacilityRoomTypeCalendar'
import RoomAssignmentRoomType from './RoomAssignmentRoomType'
import BaseCalendar from '@/components/Calendar/BaseCalendar.vue'
import SearchBooking from '@/components/SearchBooking'

export default {
  name: 'CalendarRoomAssignment',
  components: {
    FacilityRoomTypeCalendar,
    RoomAssignmentRoomType,
    BaseCalendar,
    SearchBooking
  },
  data () {
    return {
      facilityList: [],
      forTimeFilterSelected: 1,
      inventoryCountsByFacility: {},
      selectRoomType: [],
      arrChangeFrom: [],
      facilities: []
    }
  },

  mounted () {
  },

  methods: {
    refreshRoomTypes (roomTypeIds) {
      this.$refs.calendar.refreshRoomTypes(roomTypeIds)
    }
  },
  apollo: {
    facilities: {
      query: gql`query {
        facilityList (loadRoomType: true) {
          shortName
          roomTypes {
            id
            name
          }
        }
      }`,
      update: (data) => data.facilityList
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep {
  .t-calendar-item.--header .t-calendar-item__title {
    font-size: 12px !important;
  }
  .t-calendar-item__title, .t-calendar-item__data-item {
    font-size: 11px !important;
    min-height: 20px;
    height: auto;
  }
  .t-calendar-item__data-item {
      width: 100px !important;
  }
}
</style>
